import { Layout } from "components";
import PageNotFound from "components/ErrorPages/PageNotFound";
import baseModel from "models/base";

export default function Custom404() {
  const base = baseModel({
    meta: {
      title: "Page not found",
    },
    header: {
      title: {
        heading: "404 - Page not found",
      },
      breadcrumbs: [{ title: "Page not found", isCurrent: true }],
    },
  });

  return (
    <Layout base={base} isError>
      <PageNotFound />
    </Layout>
  );
}
