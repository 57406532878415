import { Button, Panel } from "components";
import { useRouter } from "next/router";
import classNames from "classnames/bind";
import styles from "./PageNotFound.module.scss";
import { NoLink } from "lib/icons";

const cx = classNames.bind(styles);

const PageNotFound = () => {
  const router = useRouter();

  return (
    <Panel>
      <div className={cx("container")}>
        <div className={cx("logo")}>
          <NoLink />
        </div>
        <div className={cx("errorMessage")}>
          <h4>
            Sorry, but we couldn&apos;t find the page you are looking for.
          </h4>
        </div>
        <div className={cx("errorSolution")}>
          <p>
            If you typed the address into your browser, please check you got it
            right.
          </p>
        </div>
        <div className={cx("refreshButton")}>
          <Button onClick={() => router.back()} theme="secondary">
            Back
          </Button>
        </div>
      </div>
    </Panel>
  );
};

export default PageNotFound;
